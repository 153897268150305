const FaqList = [
  {
    id: 1,
    title: "SGAEM은 게임 동아리인가요?",
    content:
      "아닙니다. SGAEM은 e스포츠 대회 및 이벤트를 개최하는 e스포츠 동아리입니다.",
  },
  {
    id: 2,
    title: "대회 말고도 부원이 아닌 학생도 참여할 수 있는 활동이 있나요?",
    content:
      "부원이 아닌 사람들도 SGAEM의 콘텐츠를 즐길 수 있습니다. 대회 이외에도 거리제를 즐길 수도 있으며, 거리제와 대회 이외의 다른 이벤트들(ex:서강팟지)에 참여하실 수 있습니다.",
  },
  {
    id: 3,
    title: "SGAEM 동아리 모집은 언제 언제 있나요?",
    content:
      "1학기, 2학기 초에 진행되는 정기모집과, 필요시 진행하는 특별 모집이 존재합니다. 각 모집 공고는 SGAEM 웹페이지나 인스타 팔로우를 통해 확인 가능합니다.",
  },
  {
    id: 4,
    title:
      "SGAEM 출신 졸업생분들이 e스포츠 및 게임 관련 업계에 진출하기도 하나요?",
    content:
      "네 많은 선배님들께서 다양한 e스포츠, 게임 산업계 전반에 걸쳐 진출해 계십니다.",
  },
  {
    id: 5,
    title: "SGAEM에 최소 활동 기간은 존재하나요?",
    content:
      "최소 활동 기간은 2개 학기 연속 활동입니다. 하지만, 원활한 동아리 운영을 위해 3개 학기 이상 활동을 권장합니다.",
  },
  {
    id: 6,
    title: "SGAEM에서 자신이 원하는 대회를 열 수 있나요",
    content:
      "얼마든지 가능합니다. 하지만, 다른 대회(종목)의 첫 출발이 그러했듯이, 대회를 연다는 것은 단순히 개최를 하는 것이 아니라 체계적인 준비와 기획을 통하여 열어야함을 알고있어야 합니다.",
  },
  {
    id: 7,
    title: "SGAEM에 들어가면 꼭 해당 팀에 대한 업무만 해야하나요?",
    content:
      "아닙니다. 자신의 능력이 허용하는 한에서 자유롭게 팀 활동을 하여도 됩니다. 많은 부원들이 복수의 팀에서 활동하고 있으며, 타 팀의 대한 세부 사항도 SGAEM에서 사용하는 업무 관리 프로그램인 notion을 통해 얼마든지 확인 가능합니다.",
  },
  {
    id: 8,
    title: "SGAEM에서 회의나 일정체크는 어떻게 진행되나요",
    content:
      "Notion 및 Timetree를  이용하여 회의와 일정을 체크합니다. 정기 회의나 정기적인 일정은 요일마다 고정되어있지만, 필요시 비정기적인 회의가 얼마든지 진행될 수 있습니다.",
  },
  {
    id: 9,
    title: "동아리방에서는 어떤 활동을 하나요?",
    content:
      "동아리방은 동아리 원들을 위한 자치공간입니다. 이 공간은 대회 진행시에는 스튜디오로 사용되지만, 공식 업무적으로 사용하는 날짜가 아닌 경우에는 자유로이 동아리방에서 휴식을 취하거나 취식을 할 수 있으며, 구비된 컴퓨터를 통해 과제를 하거나 게임을 하셔도 됩니다. 단, 자신의 행동으로 인하여 타인이 피해보는 일이 없도록 주의하여야 하며, 동아리방 및 동아리 물품을 사용 후 정리하여 청결을 유지합니다.",
  },
  {
    id: 10,
    title: "스겜 부원도 대회나 이벤트에 참여할 수 있나요?",
    content:
      "가능합니다. 하지만 자신이 주최에 관여한 종목에 대해서는 참가가 불가능합니다. 이는 타 참가자와의 형평성 때문입니다. 그렇지 않은 종목이라면, 자유로이 대회나 이벤트나 참여 가능합니다. 하지만 이것은 대회에 따라 바뀔 수 있습니다.",
  },
  {
    id: 11,
    title: "회의는 어떤 형식으로 진행되나요?",
    content:
      "회의는 전체 회의와 팀별 회의로 나뉩니다. 전체 회의의 경우 대면 회의로 진행하며, 업무 진행 현황 보고와 부원들의 투표가 필요한 안건을 다룹니다. 팀별 회의는 업무를 진행하기 위한 회의이며, 때에 따라 비대면으로 진행되기도 합니다. 전체 회의 이후 종종 회식을 통해 친목을 도모합니다.",
  },
  {
    id: 12,
    title:
      "콘텐츠팀에 가입하려는데 어떤 프로그램을 다룰 수 있으면 도움이 되나요?",
    content:
      "콘텐츠팀은 포스터같은 그래픽 작업물과, 영상 작업물을 생산합니다. 그래픽 작업에는 포토샵을 주로 이용하며, 영상 편집 작업에는  프리미어프로와 애프터이펙트를 주로 사용합니다. 하지만 이외에도 프로크리에이트, 미리캔버스, 그림판, 파워포인트 등으로 충분히 작업물을 제작할 수 있기 때문에 위에 언급된 프로그램이 필수는 아닙니다.",
  },

  {
    id: 13,
    title: "SGAEM이 기업과 협업한 이벤트는 무엇이 있었나요? ",
    content:
      "2018년 블리자드 데이, 2019년 테스파 스폰서십, 크래프톤 공인 대학생 리그 개최 등이 있으며, 2022년부터는 더욱더 많은 협업을 기획중에 있습니다.",
  },
];
export default FaqList;
